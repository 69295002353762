
.users-table {
  border-collapse: collapse;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.users-table th,
.users-table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  padding: 1rem;
}
.users-table th {
  background-color: #f2f2f2;
}

.searchBarUsers {
  margin-bottom: 10px;
  padding: 10px 45px 10px 15px ;
  width: 98vw;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 1rem;
  transition: border-color 0.3s, box-shadow 0.3s;
}
.searchBarUsers:focus {
  /* border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); */
  outline: none;
}
.status-barUsers{
  width: 98vw;
  border: 1px solid #dee2e6;
  margin-bottom: 20px;
  font-family: "Jost", sans-serif;
}

.status-optionUser {
  padding: 10px;
  cursor: pointer;
  background-color: #f2f2f2;
  margin-right: 10px;
  padding: 15px 30px;
  flex-grow: 1;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}
.status-optionUser:hover {
  background-color: #ddd;
}
.status-optionUser.active {
  color: white;
  background-color: #4caf50; /* Adjust this color to suit your design */
  border-radius: 5px;
}

.view-profile-button {
  background-color: #4caf50;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
}

.view-profile-button:hover {
  background-color: #45a049;
}
.userSearchDelete{
  top:-38px;
  right: 43px;
}
.rowClick{
  cursor: pointer;
}
@media (max-width:768px){
  .view-profile-button {
    padding: 8px 8px;
    font-size: 8px;
    margin: 2px 2px;
  }
  .searchBarUsers {
    padding: 10px 45px 6px 15px ;
    font-size: 0.8rem;
  }
  .status-optionUser {
    font-size: 0.575rem;
  }
  .userSearchDelete{
    top:-43px;
    right: 30px;
  }
  .hide-on-mobile{
    display: none;
  }
}

@media (max-width:425px){
  .searchBarUsers {
    padding: 10px 0px 6px 15px;
    font-size: 0.8rem;
  }
}