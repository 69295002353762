.habitCardsWrapper{
    width: 50%;
    padding:30px;
    margin:30px;
}
.adoptHabits h1{
    font-family: "Jost", sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 20px;
}
.habitCardHeading{
    font-size:25px;
    font-family: "Jost", sans-serif;
    font-weight: 500;
}
.habitCardContent{
    font-family: "Jost", sans-serif;
}
.habitContentImage{
    width: 100%;
    background-color: #FAEF5D;
    justify-content: space-around;
}
.habitContent{
    width: 30%;
    padding: 30px;
    margin-left: 20px;
}
.habitImage{
    width: 50%;

}
.habitImage img{
    width: 700px;
    height: auto;
    margin-top:-250px
} 
.habitCard{
    width: 50%;
    padding-left: 40px;
    margin-top: 20px;
}
@media screen and (max-width:768px) and (min-width:320px) {
    .habitImage img{
        display: none;
    } 
    .habitContent{
        width: 100%;
    }
    .habitImage{
        width: 0%;
    }
    .habitCard{
        width: 100%;
    }
    .habitCard{
        width: 100%;
        padding-left: 0px;
        margin-top: 30px;
    }
    .habitCardsWrapper{
        width: 90%;
        padding:20px 20px ;
        margin:30px;
    }
    .habitCardHeading{
        font-size:22px;
    }
   
}
@media screen and (max-width:1024px) and (min-width:769px) {
    .habitCardsWrapper{
        width: 70%;
    }
    .habitImage img{
        margin-top:-100px
    } 
}