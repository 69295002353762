
.moneyWallpaper{
    background-image: url(../../Assets/money_rupee+background.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}
.contactFormWrapper{
    width: 80%;
    margin-top:40px;
    padding: 20px;
    /* background-color: yellow; */
}
.contactFormPhrase{
    font-size: 50px;
}
.socialMediaIcons{
    width: 40px;
    height: 40px;
    margin-right: 15px;
}
.contactDetails{
    font-size: 25px;
}
.contactContentBlock{
    width: 40%;
}
.contactFormDetails{
    width: 40%;
}
.contactFormDetails form div input{
    width: 230px;
    background-color: #EAEAEA;
}
.contactFormDetails form div{
    margin-top:20px;
}
.contactFormDetails form div label{
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 22px;
}
.contactFormBtn{
    width: 230px;
    color: #EAEAEA;
    background-color: black;
}
@media screen and (min-width:400px) and (max-width:767px) {
    .contactContentBlock{
        padding-left: 20px;
        }
}

@media screen and (max-width:767px) and (min-width:320px) {
    .contactFormPhrase{
        font-size:25px;
    }
    .contactFormWrapper{
        width: 100%;
        margin-top:40px;
        padding: 20px;
    }
    .contactDetails{
        font-size: 13px;
        padding: 0 !important;
    }
    .contactContentBlock{
        width: 100%;
        /* background-color: yellow; */
    }
    .contactFormDetails{
        width: 100%;
    }
    .socialMediaIcons{
        width: 30px;
        height: 30px;
        margin-right: 15px;
    }
    .moneyWallpaper{
        background-image:none;
    }
}

@media screen and (max-width:1024px) and (min-width:768px) {
    .contactFormPhrase{
        font-size:30px;
    }
    .contactFormWrapper{
        width: 100%;
        margin-top:40px;
        padding: 20px;
    }
    .contactDetails{
        font-size: 15px;
        padding: 0 !important;
    }
    .contactContentBlock{
        width: 100%;
        /* background-color: yellow; */
    }
    .contactFormDetails{
        width: 100%;
    }
    .socialMediaIcons{
        width: 35px;
        height: 35px;
        margin-right: 15px;
    }
  
}