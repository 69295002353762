.impFinanceCards{
    margin-top: 50px;
}
.impFinanceLiteracyWrapper .impFinanceLitHeading h1{ 
    font-family: "Jost", sans-serif;
    font-weight:500;
    padding: 20px;
    margin-left:140px;
}
.impFinanceLiteracyWrapper{
    background-image: url(../../Assets/finance_literacy.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: auto;
}

.financeCard{
    width: 300px;
    padding: 20px;
    margin: 30px;
    background-color: #F9F9F9;
    border-radius: 40px;
    -webkit-box-shadow: -2px 0px 15px 0px rgb(223, 223, 223);
    -moz-box-shadow: -2px 0px 15px 0px rgb(223, 223, 223);
    box-shadow: -2px 0px 15px 0px rgb(223, 223, 223);
    font-family: "Jost", sans-serif;
}
.financeCard h2{
    font-family: "Josefin Sans", sans-serif;
    font-weight: 500;

}
.financeCard div{
    font-family: "Jost", sans-serif;
}

@media screen and (max-width:576px)  {
    .impFinanceLiteracyWrapper{
        background-image: none;
    }
    .financeCard{
        padding: 15px;
        margin:  15px;
    }
    .impFinanceLiteracyWrapper .impFinanceLitHeading h1{ 
        font-size: 20px;
    }
    .impFinanceCards{
        margin-top: 10px;
        
    }
    .impFinanceLiteracyWrapper .impFinanceLitHeading h1{ 
        margin-left:0px;
        font-size: 25px;
    }
    .financeCard>h2{
        font-size: 20px;
    }
    
}
@media screen and (max-width:768px) and (min-width:577px) 
    {
        .financeCard{
            width: 170px;
            padding: 13px;
            margin: 13px;
        }
        .impFinanceLiteracyWrapper{
            background-image: none;
        }
        .financeCard h2{
            font-size: 20px;
        
        }
        .financeCard div{
            font-size: 12px;
        }
        .financeCard{
            border-radius: 20px;
        }
        .impFinanceLiteracyWrapper .impFinanceLitHeading h1{ 
            margin-left:0px;
            font-size: 25px;
        }
       
    }
  
    @media screen and (max-width:1024px) and (min-width:575px) 
    {
        .financeCard{
            width: 200px;
            padding: 13px;
            margin: 30px;
        }
        .impFinanceLiteracyWrapper{
            background-size: 300px;
        }
        .financeCard h2{
            font-size: 20px;
        
        }
        .financeCard div{
            font-size: 12px;
        }
        .financeCard{
            border-radius: 20px;
        }
        .impFinanceLiteracyWrapper .impFinanceLitHeading h1{ 
            margin-left:0px;
            font-size: 25px;
        }
        .impFinanceCards{
            margin-top: 0px;
        }
    }
  