.impactOfFinanceLitOnIndOverviewWrapper h1{
    font-family: "Jost", sans-serif;
    font-weight:500;
    padding: 20px;
    margin-left:140px;
}
.impactOfFinanceIndividualCardsWrapper{
        width: 90%;
}
.FinanceLitOnIndividualCard{
    margin-bottom: 10px;
}
.indFinanceLitImageWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #BBFF64;
    width: 150px;
    height: 60px;
    margin: 20px;
}
.individualFinanceLitImage{
    width: 30px;
    height: 30px;
}
.impactsOfFinanceIndImageWrapper{
    width: 50%;
}
.indFinanceLitContentWrapper{
    margin-bottom: 20px;
}
.indFinanceLitContentWrapper div{
    font-family: "Jost", sans-serif;
    
}
.indFinanceLitContentWrapper h2{
    font-family: "Josefin Sans", sans-serif;
    font-weight: 500;
}
.impactsOfFinanceIndCards{
    width: 40%;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width:768px) {
    .impactOfFinanceIndividualCardsWrapper{
        flex-direction: column;
}
.impactOfFinanceIndividualCardsWrapper{
    width: 100%;
}
.impactsOfFinanceIndCards{
    width: 90%;
    flex-direction: column;
}
.impactOfFinanceLitOnIndOverviewWrapper h1{
    margin-left:0px;
    font-size: 25px;
}
.impactOfIndFinImage{
    width: 300px;
}
.indFinanceLitImageWrapper{
   display: none;
}
.impactsOfFinanceIndImageWrapper{
    width: 100%;
}
    
}
@media screen and (max-width:1024px) and (min-width:769px) {
    .indFinanceLitImageWrapper{
        width: 220px;
        height: 50px;
    }
   
}
