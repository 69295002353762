.newsLetter{
    width: 80%;
}
.newLetterPhrase{
    width: 40%;
    padding: 30px;
}
.newsLetterEmail{
    width: 40%;
}
.newsLetterWrapper{
    display: flex;
    padding:30px;
    justify-content: center;
    align-items: center;
    background-image: url(../../Assets/finance_HomeFinal.png);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #F3F3F3;
    font-family: "Jost", sans-serif;
}
.newsLetterEmail form input , .newsLetterEmail form button{
    -webkit-box-shadow: -1px -1px 8px 0px rgba(221, 221, 221, 0.75);
-moz-box-shadow: -1px -1px 8px 0px rgba(221, 221, 221, 0.75);
box-shadow: -1px -1px 8px 0px rgba(221, 221, 221, 0.75);
    border:none;
    padding: 10px;
}
.newsLetterEmail form button{
    background-color: #DFFFB6;
    padding: 10px;
}
@media screen and (max-width:768px) and (min-width:320px) {
    .newsLetterWrapper{
        padding:30px;
        background-image:none;
    }
    .newsLetter{
        width: 100%;
    }
    .newLetterPhrase{
        width: 100%;
        padding: 10px;
    }
    .newsLetterEmail{
        width: 100%;
    }
    .newLetterPhrase h1{
        font-size: 25px;
    }
}