.maintanenceImg img{
    width: 25%;
    
}
.underMaintenanceWrapper{
    margin-top: 10%;
  /* font-family: "Questrial", sans-serif; */ 
    font-family: "Josefin Sans", sans-serif;
}


@media screen and (max-width:768px) {
 .maintenanceText h1{
    font-size: 16px;

 }
 .maintenanceText h2{
    font-size: 14px;

 }
 .maintenanceText p{
    font-size: 16px;
 }
}