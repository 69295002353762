.homeContentWrapper {
  height: 100%;
  width: 40%;
  padding-left: 50px;
  font-family: "Jost", sans-serif;
}
.companyLogo {
  width: 300px;
  height: auto;
}
.homeWrapper {
  width: 100%;
  height: 600px;
  background-color: #faef5d;
  background-image: url(../../Assets/finance_HomesTesting.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  background-position-y: bottom;
}
.exploreMoreBtn {
  padding: 15px;
  border-radius: 20px;
  border: none;
  -webkit-box-shadow: -2px 0px 15px 0px rgba(171, 171, 171, 1);
  -moz-box-shadow: -2px 0px 15px 0px rgba(171, 171, 171, 1);
  box-shadow: -2px 0px 15px 0px rgba(171, 171, 171, 1);
  font-family: "Jost", sans-serif;
}

@media screen and (max-width: 576px) {
  .companyLogo {
    width: 150px;
    height: auto;
  }
  .homeContentWrapper {
    width: 80%;
    height: 80%;
    font-size: 13px;
    justify-content: center;
    padding-left: 20px;
  }
  .financeIntro {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 768px) and (min-width: 577px) {
  .companyLogo {
    width: 150px;
    height: auto;
  }
  .homeContentWrapper {
    width: 60%;
    font-size: 16px;
    justify-content: center;
    padding-left: 20px;
  }
  .financeIntro {
    margin-bottom: 40px;
  }
  .homeWrapper {
    background-size: auto;
  }
}
@media screen and (max-width: 1024px) and (min-width: 769px) {
  .homeWrapper {
    background-size: auto;
  }
}
