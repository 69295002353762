.nbfcs-container {
  padding: 10px;
}

.nbfcs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
}

.nbfc-card {
  display: flex;
  flex-direction: column;
  background: #fff;
  height: 28rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: "Montserrat", sans-serif;
}

.nbfc-card-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.nbfc-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom: 1px solid rgb(202, 202, 202);
}

.nbfc-card-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nbfc-card-content h2 {
  margin-top: 0;
  font-size: 1.5em;
}

.nbfc-card-content p {
  margin: 5px 0;
  font-size: 14px;
}

.nbfc-card button {
  /* margin-top: 10px; */
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.nbfc-card button:hover {
  background-color: #0056b3;
}

.ratingColor {
  background-color: #BAE475;
  padding: 0;
  padding-top:5px;
}
.noNbfcDataWrapper{
  width: 100%;
  font-family: "Montserrat", sans-serif;
  animation: mymove 1.3s forwards;
  z-index:-1;
}

@keyframes mymove {
  from {top: -600px;}
  to {top: 0px;}
}
.noNbfcsFound{
  width: 40%; 
  height: auto;
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .nbfcs-grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

@media (max-width: 992px) {
  .nbfcs-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media (max-width: 768px) {
  .nbfcs-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media (max-width: 576px) {
  .nbfcs-grid {
    grid-template-columns: 1fr;
  }
  .noNbfcDataWrapper h2{
    font-size: 1rem;
  }
}
