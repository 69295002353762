.navBarWrapper{
  background-color: #faef5d;
  z-index: 3;
}

.kakatiyaLogo{
  width: 200px ;
  height: auto;
}
.custom-toggler img {
  width: 30px;
  height: 30px;
}
.navbar-toggler {
  box-shadow: none !important;
}
.profileImg {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.navElements a{
  font-size: 1.2rem;
  font-family: "Josefin Sans", sans-serif;
  margin:0px 10px 0px 10px;
  text-decoration: none;
  color: rgb(53, 53, 53);
}
.navElements  .active{
background-color: cadetblue;
border-radius: 4px;
color: white;
padding: 0px 5px 0px 5px ;
}

@media screen and (max-width:922px) and (min-width:320px){
  .navElements a{
    margin:10px 10px 10px 10px;
    text-transform:uppercase;
  }
  .navElements .active{
    background-color: transparent;
    color: gray;
    }
}