.financialKnowledgeImages{
    width: 100px;
    height: 100px;
}
.financialKnowledgeImages .finKnowImage{
    width: 50px;
    height: 50px;
}
.financialKnowledgeCards{
    margin-top: 100px;
    width: 100%;
}
.financeCardsWrapper{
    width: 70%;
    background-color: #FAEF5D;
    border-radius: 50px;
}
.Heading_moneyImg{
    width: 100%;
}
.moneyBag{
    right:-50px;
    top: -120px;
    width: 200px;
    height: auto;
}
.financeCards{
    padding: 20px;
}
.financialKnwoledgeCard{
    width: 25%;
    padding: 10px;
    margin: 10px;
    margin-top: 50px;
}
.financeLitHeading{
    padding: 20px 0px 0px 20px;
    margin-left: 40px;
    font-family: "Jost", sans-serif;
    font-weight: 500;
}
.financialKnwoledgeCard h2{
    padding: 16px;
    font-size: 22px;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 500;
}
.financialKnwoledgeCard div{
    font-family: "Jost", sans-serif;
}

@media screen and (max-width:768px) and (min-width:577px) {
    .financialKnwoledgeCard h2{
        font-size: 15px;
    }
    .financialKnwoledgeCard div{
        font-size: 12px;
    }
       .financialKnwoledgeCard{
            margin-top: 0px;
    }
    .financialKnwoledgeCard{
        width: 26%;
    }
    .financeCardsWrapper{
        width: 80%;
    }
    
    
}

@media screen and (max-width:767px) and (min-width:320px) {
    .financialKnwoledgeCard{
        width: 100%;
        margin:5px;
        margin-top: 50px;
    }
    .moneyBag{
        display: none;
    }
    .financeLitHeading{
        margin-left: 10px;
    }
    .financeCardsWrapper{
        width: 100%;
        border-radius: 0px;
    }
}