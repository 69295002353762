
.challengesInFinContent{
    width: 60%;
}
.challengesInFinCardsWrapper{
    width: 80%;
}
.challengesInFinCard{
    width: 50%;
    margin:3px,
}
.challengesInFinLiteracy>.challengesInFinContent>.challengesInFInLitHeading{
    width: 80%;
}
.challengesInFinCard h1{
    font-size: 25px;
    text-align: left;
}
.challengesInFinCard div{
    font-size: 14px;
}
.moneyGirlImage{
    width: 40%;
}
.moneyGirl{
    width: 700px;
}
.challengesInFinLiteracy{
    background-color: #F3F3F3;
}
.challengesInFInLitHeading h1,.challengesInFinCardsWrapper h1{
    font-family: "Josefin Sans", sans-serif;
    font-weight: 500;
}
.challengesInFinCardsWrapper div{
    font-family: "Jost", sans-serif;
}
@media screen and (max-width:768px) and (min-width:320px) {
    .challengesInFinContent{
        width: 100%;
    }
    .moneyGirl{
        display: none;
    }
    .challengesInFinCard{
        width: 100%;
        margin:0;
    }
    .moneyGirlImage{
        width: 0%;
    }
}